import useLanguage from "../../../hooks/language";
import Clinic, { formatNextAvailableDate } from "../../../models/clinic";
import styles from "./clinic-card.module.css";
import React from "react";
import { useIsFeatureFlagEnabled } from "pages/home/feature-flag-context/featureFlagContext";
interface ClinicCardProps {
    clinic: Clinic;
    hasDateSelection?: boolean;
    isVisible?: boolean;
    isFlagEnabled?: boolean;
}

const ClinicCard: React.FunctionComponent<ClinicCardProps> = ({
    clinic,
}: ClinicCardProps) => {
    const { translations, language } = useLanguage();
    const t = translations.humanizeDateFormatter;

    const dateString = formatNextAvailableDate(clinic, language);
    const isFeatureFlagEnabled = useIsFeatureFlagEnabled(
        "hide-next-available-date",
        "on"
    );

    return (
        <div className={styles.cardContainer}>
            <div className={styles.infoContainer}>
                <h3>{clinic.name}</h3>
                {isFeatureFlagEnabled ? (
                    <></>
                ) : (
                    <p>
                        {dateString ? (
                            <span>
                                {t.nextAvailableTime} <b>{dateString}</b>
                            </span>
                        ) : (
                            t.unknownDate
                        )}
                    </p>
                )}
                <a
                    href={`https://maps.google.com/?q=${clinic.address}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {clinic.address}
                </a>
            </div>
        </div>
    );
};

export default ClinicCard;
