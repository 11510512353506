import useLanguage from "../../../hooks/language";
import { kebabCase } from "lodash";
import { useEffect, useState } from "react";
import { CheckIcon, ClockIcon, MapPinIcon } from "./icons";
import { DateTime } from "luxon";
import useAuthUser from "../../../hooks/auth-user";
import { DEFAULT_TIMEZONE } from "../../../utils/constants";
import { getNextAppointment } from "../../../utils/appointment-helpers";
import { createIcsFile } from "../../../utils/generateIcsFile";
import styles from "./appointment-confirmation.module.css";

export default function AppointmentConfirmation(): JSX.Element {
    const { translations, language } = useLanguage();
    const tConfirmation = translations.appointmentConfirmation;
    const tReminders = tConfirmation.reminders;
    const icsFileName = kebabCase(tConfirmation.icsFileTitle) + ".ics";

    const [authUser] = useAuthUser();
    const [appointmentIcsFile, setAppointmentIcsFile] = useState<File>();
    const [icsFileUrl, setIcsFileUrl] = useState<string>();
    const nextAppointment = getNextAppointment(authUser);

    const [
        formattedAppointmentDate,
        setFormattedAppointmentDate,
    ] = useState<string>("");

    useEffect(() => {
        if (!nextAppointment) return;

        const nextAppointmentDateTime = DateTime.fromISO(
            nextAppointment.dateTime,
            {
                zone: DEFAULT_TIMEZONE,
            }
        );
        const formattedAppointmentDate =
            nextAppointmentDateTime.setLocale(language).toLocaleString({
                month: "long",
                day: "numeric",
                year: "numeric",
            }) + `, ${nextAppointmentDateTime.toFormat("h:mm a")}`;
        setFormattedAppointmentDate(formattedAppointmentDate);
        (async () => {
            const icsFileData = await createIcsFile({
                calendarEventTitle: tConfirmation.title,
                nextAppointment,
                fileName: icsFileName,
                language,
                timeZone: DEFAULT_TIMEZONE,
            });
            if (icsFileData) {
                const { url, icsFile } = icsFileData;
                setAppointmentIcsFile(icsFile);
                setIcsFileUrl(url);
            }
        })();

        return () => {
            if (icsFileUrl) {
                URL.revokeObjectURL(icsFileUrl);
            }
        };
    }, [authUser, language]);

    return (
        <div className={`container--narrow ${styles.confirmationContainer}`}>
            <div>
                <div className={styles.confirmationHeader__icon}>
                    <CheckIcon />
                </div>
                <h1 className={styles.confirmationHeader__title}>
                    {tConfirmation.title}
                </h1>

                <p className={styles.confirmationHeader__subtitle}>
                    {tConfirmation.subtitle}{" "}
                    <a className={styles.link} href="mailto:info@prelib.com">
                        info@prelib.com
                    </a>
                    .
                </p>
            </div>

            <div>
                {nextAppointment?.dateTime && (
                    <div className={styles.appointmentDetails}>
                        <ClockIcon />
                        <div>
                            <span className={styles.appointmentDetails__title}>
                                {formattedAppointmentDate}
                            </span>
                            <span
                                className={styles.appointmentDetails__linkList}
                            >
                                {nextAppointment?.addToGoogleCalendarLink && (
                                    <span
                                        className={
                                            styles.appointmentDetails__linkItem
                                        }
                                    >
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={
                                                nextAppointment?.addToGoogleCalendarLink
                                            }
                                            className={styles.link}
                                        >
                                            Google Calendar
                                        </a>
                                    </span>
                                )}
                                {nextAppointment?.addToOutlookCalendarLink && (
                                    <span
                                        className={
                                            styles.appointmentDetails__linkItem
                                        }
                                    >
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={
                                                nextAppointment?.addToOutlookCalendarLink
                                            }
                                            className={styles.link}
                                        >
                                            Outlook
                                        </a>
                                    </span>
                                )}
                                {appointmentIcsFile && (
                                    <span
                                        className={
                                            styles.appointmentDetails__linkItem
                                        }
                                    >
                                        <a
                                            href={icsFileUrl}
                                            download={icsFileName}
                                            aria-label={`Download calendar file: ${icsFileName}`}
                                            className={styles.link}
                                        >
                                            ICalendar
                                        </a>
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                )}

                {nextAppointment?.clinic && (
                    <div className={styles.appointmentDetails}>
                        <MapPinIcon />

                        <div>
                            <span className={styles.appointmentDetails__title}>
                                {nextAppointment?.clinic?.name}
                            </span>
                            <span
                                className={
                                    styles.appointmentDetails__description
                                }
                            >
                                {nextAppointment?.clinic.address}
                            </span>
                            <span
                                className={styles.appointmentDetails__linkList}
                            >
                                <span
                                    className={
                                        styles.appointmentDetails__linkItem
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.google.com/maps/search/${nextAppointment.clinic.address}`}
                                        className={styles.link}
                                    >
                                        Google Maps
                                    </a>
                                </span>
                            </span>
                        </div>
                    </div>
                )}
            </div>

            <div>
                <p className={styles.confirmationFooter__title}>
                    {tReminders.title}
                </p>
                <ul className={styles.confirmationFooter__contentList}>
                    <li className={styles.confirmationFooter__contentItem}>
                        <strong>{tReminders.bringRAMQ.main}</strong>
                        {tReminders.bringRAMQ.note}
                    </li>
                    <li className={styles.confirmationFooter__contentItem}>
                        <strong>{tReminders.beOnTime.main}</strong>
                    </li>
                    <li className={styles.confirmationFooter__contentItem}>
                        <strong>{tReminders.cancelEarly.main}</strong>
                        {tReminders.cancelEarly.note}
                    </li>
                </ul>
            </div>

            {nextAppointment?.followUpType && (
                <p
                    className={styles.confirmationFooter_footNote}
                    dangerouslySetInnerHTML={{
                        __html:
                            tConfirmation.serviceDescription[
                                nextAppointment.followUpType
                            ],
                    }}
                />
            )}
        </div>
    );
}
