import Axios from "axios";
import useAnalytics from "hooks/analytics";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserActionEvent } from "utils/analytics/events";
import { AppointmentCard, Button, Modal } from "../../../components";
import Tabs, { TabInterface as Tab } from "../../../components/tabs";
import useAuthUser from "../../../hooks/auth-user";
import useLanguage from "../../../hooks/language";
import {
    CancelAppointmentModal,
    EditAppointmentModal,
    RejectPhoneAppointmentModal,
    ScheduleAppointmentModal,
    SchedulePhoneAppointmentModal,
    DoubleFeedbackModal,
} from "../../../modals";
import Appointment, {
    getPhoneAppointmentToSchedule,
} from "../../../models/appointment";
import PhoneAppointment, {
    getLinkedAppointmentDateTime,
} from "../../../models/phone-appointment";
import {
    appointmentWithSatisfactionQuestionnairePrompt,
    isAppointment,
    isUpcoming,
    sortAppointments,
} from "../../../utils/appointment-helpers";
import ENDPOINTS from "../../../utils/endpoints";
import ROUTES from "../../../utils/routes";
import styles from "./my-appointments.module.css";
import { FeatureFlagContextProvider } from "../feature-flag-context/featureFlagContext";

type AppointmentAction =
    | "edit"
    | "reject"
    | "reschedule"
    | "schedule-retake"
    | "schedule-phone-appointment"
    | "schedule-follow-up-appointment"
    | "feedback";

interface IMyAppointmentsProperties {
    tabHeaderClassName?: { [key: string]: any };
}

const MyAppointments: React.FunctionComponent<IMyAppointmentsProperties> = (
    props: IMyAppointmentsProperties
) => {
    const { translations, language } = useLanguage();
    const [authUser, setAuthUser] = useAuthUser();
    const { push } = useHistory();
    const t = translations.appointmentsSection;
    const [analytics] = useAnalytics();

    // States

    const [modalAppointment, setModalAppointment] = useState<Appointment>();
    const [
        modalPhoneAppointment,
        setModalPhoneAppointment,
    ] = useState<PhoneAppointment>();

    const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [
        scheduleAppointmentModalVisible,
        setScheduleAppointmentModalVisible,
    ] = useState(false);
    const [
        schedulePhoneAppointmentModalVisible,
        setSchedulePhoneAppointmentModalVisible,
    ] = useState(false);

    // Effects
    useEffect(() => {
        if (!authUser) return;

        Axios.put(ENDPOINTS.APPOINTMENTS, {});

        const appointmentNeedImmediateFeedBack = appointmentWithSatisfactionQuestionnairePrompt(
            authUser.appointments
        );
        // If user has a satisfaction questionnaire to fill, he/she shall b prompted
        if (appointmentNeedImmediateFeedBack)
            onClickAppointmentButton(
                appointmentNeedImmediateFeedBack,
                "feedback"
            );
    }, [authUser]);
    // Handlers

    const onClickAppointmentButton = (
        appointment: Appointment | PhoneAppointment,
        action: AppointmentAction
    ) => {
        if (isAppointment(appointment)) {
            setModalAppointment(appointment);

            if (action === "schedule-follow-up-appointment") {
                setModalPhoneAppointment(
                    getPhoneAppointmentToSchedule(appointment)
                );
            }
        } else {
            setModalPhoneAppointment(appointment);
        }

        switch (action) {
            case "edit":
                setEditModalVisible(true);
                break;
            case "reject":
                setRejectModalVisible(true);
                break;
            case "reschedule":
                // TODO: Implement fee payment when rescheduling
                setScheduleAppointmentModalVisible(true);
                break;
            case "schedule-retake":
                setScheduleAppointmentModalVisible(true);
                break;
            case "schedule-phone-appointment":
                setSchedulePhoneAppointmentModalVisible(true);
                break;
            case "schedule-follow-up-appointment":
                setSchedulePhoneAppointmentModalVisible(true);
                break;
            case "feedback":
                analytics
                    ?.cdp()
                    ?.trackEvent(UserActionEvent.AppointmentReviewInitiated);
                setFeedbackModalVisible(true);
                break;
        }
    };

    const onAppointmentChange = async () => {
        const { data } = await Axios.get(ENDPOINTS.ME);
        setAuthUser(data);

        setModalAppointment(undefined);
        setModalPhoneAppointment(undefined);

        setEditModalVisible(false);
        setCancelModalVisible(false);
        setRejectModalVisible(false);
        setScheduleAppointmentModalVisible(false);
        setSchedulePhoneAppointmentModalVisible(false);
        setFeedbackModalVisible(false);
    };

    const mapAppointmentToCard = (
        appointment: Appointment | PhoneAppointment
    ) => (
        <AppointmentCard
            key={appointment.id}
            appointment={appointment}
            onEditClick={() => onClickAppointmentButton(appointment, "edit")}
            onRejectClick={() =>
                onClickAppointmentButton(appointment, "reject")
            }
            onRescheduleClick={() =>
                onClickAppointmentButton(appointment, "reschedule")
            }
            onScheduleRetakeClick={() =>
                onClickAppointmentButton(appointment, "schedule-retake")
            }
            onSchedulePhoneAppointmentClick={() =>
                onClickAppointmentButton(
                    appointment,
                    "schedule-follow-up-appointment"
                )
            }
            onChooseDateClick={() =>
                onClickAppointmentButton(
                    appointment,
                    "schedule-phone-appointment"
                )
            }
            onFeedbackClick={() =>
                onClickAppointmentButton(appointment, "feedback")
            }
            onRetakeQuestionnaireClick={() => push(ROUTES.SCREENING)}
        />
    );

    // Rendering
    const appointments = authUser
        ? [...authUser.phoneAppointments, ...authUser.appointments]
        : [];

    const upcomingAppointments = appointments
        .filter((app) => isUpcoming(app))
        .sort((a, b) => sortAppointments(a, b, "asc"));
    const pastAppointments = appointments
        .filter((app) => !isUpcoming(app))
        .sort((a, b) => sortAppointments(a, b, "desc"));

    // No appointment
    const hasNoAppointment =
        upcomingAppointments.length === 0 && pastAppointments.length === 0;

    const noAppointmentElement = hasNoAppointment ? (
        <div className={styles.noAppointmentHolder}>
            <h3>{t.noAppointment}</h3>
            <div className="dual-button-holder">
                <Button onClick={() => push(ROUTES.SCREENING)} type="secondary">
                    {t.clickHereToSchedule}
                </Button>
            </div>
        </div>
    ) : null;

    const upcomingApointmentListElement =
        upcomingAppointments.length > 0 ? (
            <div>
                <div className="container">
                    {upcomingAppointments.map(mapAppointmentToCard)}
                </div>
            </div>
        ) : null;
    const pastApointmentListElement =
        pastAppointments.length > 0 ? (
            <div>
                <div className="container">
                    {pastAppointments.map(mapAppointmentToCard)}
                </div>
            </div>
        ) : null;

    // Local date time string
    const modalAppointmentDate = modalAppointment
        ? DateTime.fromISO(modalAppointment.datetime)
        : null;
    const modalAppointmentDateString = modalAppointmentDate
        ? modalAppointmentDate
              .setLocale(language)
              .toLocaleString(DateTime.DATE_MED)
        : null;

    const tabs: Tab[] = [];

    if (upcomingApointmentListElement) {
        tabs.push({
            title: t.upcomingAppointments,
            content: upcomingApointmentListElement,
        });
    }
    if (pastApointmentListElement)
        tabs.push({
            title: t.pastAppointments,
            content: pastApointmentListElement,
        });

    let prepSubtitle;
    if (modalPhoneAppointment?.type === "PREP_ELIGIBILITY") {
        const date = getLinkedAppointmentDateTime(
            modalPhoneAppointment.appointment.id,
            authUser
        );
        if (date) {
            const formatedDate = DateTime.fromISO(date).toFormat(
                "d LLL. yyyy, HH'h'mm"
            );
            prepSubtitle = `${t.rescheduleAppointmentModal.prepSubtitle} ${formatedDate}`;
        }
    }

    return (
        <div>
            {/* Edit appointment modal */}
            <Modal
                title={
                    modalAppointmentDateString
                        ? t.cancellationModal.header(modalAppointmentDateString)
                        : undefined
                }
                visible={editModalVisible}
                onVisibilityChange={setEditModalVisible}
            >
                <EditAppointmentModal
                    onRescheduleClick={() =>
                        setScheduleAppointmentModalVisible(true)
                    }
                    onCancelClick={() => setCancelModalVisible(true)}
                    canRescheduleFreely={modalAppointment?.canRescheduleFreely}
                />
            </Modal>

            {/* Cancel appointment modal */}
            <Modal
                title={t.cancellationConfirmModal.title}
                visible={cancelModalVisible}
                onVisibilityChange={setCancelModalVisible}
            >
                <CancelAppointmentModal
                    onClose={() => setCancelModalVisible(false)}
                    onConfirm={onAppointmentChange}
                    appointment={modalAppointment}
                />
            </Modal>

            {/* Reject phone appointment modal */}
            <Modal
                title={t.cancellationConfirmModal.phoneTitle}
                visible={rejectModalVisible}
                onVisibilityChange={setRejectModalVisible}
            >
                <RejectPhoneAppointmentModal
                    onClose={() => setRejectModalVisible(false)}
                    onConfirm={onAppointmentChange}
                    appointment={modalPhoneAppointment}
                />
            </Modal>

            {/* Schedule appointment modal */}
            <Modal
                title={t.rescheduleAppointmentModal.title}
                visible={scheduleAppointmentModalVisible}
                onVisibilityChange={setScheduleAppointmentModalVisible}
            >
                <FeatureFlagContextProvider
                    flags={["hide-next-available-date"]}
                >
                    <ScheduleAppointmentModal
                        isVisible={scheduleAppointmentModalVisible}
                        onClose={() =>
                            setScheduleAppointmentModalVisible(false)
                        }
                        onConfirm={onAppointmentChange}
                        appointment={modalAppointment}
                    />
                </FeatureFlagContextProvider>
            </Modal>

            {/* Schedule phone appointment modal */}
            <Modal
                title={t.rescheduleAppointmentModal.title}
                subtitle={prepSubtitle}
                visible={schedulePhoneAppointmentModalVisible}
                onVisibilityChange={setSchedulePhoneAppointmentModalVisible}
            >
                <SchedulePhoneAppointmentModal
                    onClose={() =>
                        setSchedulePhoneAppointmentModalVisible(false)
                    }
                    onConfirm={onAppointmentChange}
                    appointment={modalPhoneAppointment}
                />
            </Modal>

            {/* Feedback modals : satifaction survey followed by optional google review */}
            <DoubleFeedbackModal
                modalAppointment={modalAppointment}
                visible={feedbackModalVisible}
                onVisibilityChange={setFeedbackModalVisible}
            />

            {noAppointmentElement}

            {tabs.length > 0 && (
                <Tabs
                    tabHeaderClassName={props.tabHeaderClassName}
                    tabs={tabs}
                />
            )}
        </div>
    );
};

export default MyAppointments;
